@import '../../assets/scss/variables.scss';
$padding: 4rem;

.staking__box {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  text-align: left;
  height: fit-content;
  width: 100%;
  padding: $padding;
  padding-top: 1rem;
  padding-bottom: 3rem;
  // background: var(--bg-6);
  background: linear-gradient(
    45deg,
    rgba($color: $kbreeder-pink, $alpha: 0.2),
    rgba($color: $kbreeder-brand, $alpha: 0.4)
  );
  // border: 2px solid var(--kbreeder-brand);
  border-radius: var(--border-radius-large);

  .fees_info {
    font-size: 1.5rem;
    line-height: 1.4em;
    // color: var(--kbreeder-pink);
  }
}
.staking__pair__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  width: 100%;
  border-bottom: 5px solid #502d63;
  // border-bottom-left-radius: 10%;
  // border-bottom-right-radius: 10%;
  h5 {
    font-size: 3.8rem;
  }
  &__imgContainer {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 7rem;
    gap: 0.5rem;
    margin-right: 2rem;

    img {
      // transform: scale(0.7);
      height: 6rem;
      width: 6rem;
      border-radius: 100%;
      background: var(--title);
      border: 0.3rem solid var(--title);
      box-shadow: 2px -5px 5px inset rgba($color: #000000, $alpha: 0.3),
        0 0 5px inset rgba($color: #000000, $alpha: 0.3);
    }

    .eth__image {
      position: absolute;
      right: 0;
      height: 3rem;
      width: 3rem;
      border-width: 0.15rem;
    }
  }
}
.user__staking__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 390px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}
.stake__unstake__btns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem;
  background: rgba($color: $kbreeder-brand, $alpha: 0.4);
  border-radius: var(--border-radius-medium);
  // border: 2px solid $kbreeder-brand;
  text-align: center;
  color: rgb(192, 192, 192);

  @media screen and (max-width: 366px) {
    flex-direction: column;
  }

  .stake__unstake__btn {
    padding: 2rem;
    // margin: 1% 2%;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background 0.15s ease-out, color 0.15s ease-out;

    // Avoid user selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &-active {
      background: var(--kbreeder-brand);
      color: white;
      color: $kbreeder-pink;
    }

    &:hover:not(.stake__unstake__btn-active) {
      background: rgba($color: $kbreeder-brand, $alpha: 0.5);
    }
  }
}

.stake__unstake__box {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  &__title {
    // color: var(--kbreeder-violet);
  }
}

.user__wallet__balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0px;
  justify-content: space-between;

  @media screen and (max-width: 440px) {
    flex-direction: column;
    gap: 1rem;
    align-items: unset;
  }

  .user__balance {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    @media screen and (max-width: 440px) {
      flex-direction: column;
      align-items: unset;
    }

    h6 {
      margin-right: 5px;
    }
  }

  .user__wallet {
    display: flex;
  }

  .max__button {
    padding: 1rem;
    cursor: pointer;
    border-radius: var(--border-radius-round);
    background: rgba($color: $kbreeder-brand, $alpha: 0.5);
    color: $kbreeder-violet;
    transition: background 0.15s ease-out, color 0.15s ease-out;
    // Avoid user selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      color: $kbreeder-pink;
      background: rgba($color: $kbreeder-brand, $alpha: 0.8);
    }
  }
}

.amount__input {
  position: relative;
  outline: 0px;
  appearance: none;
  width: 100%;
  color: var(--title);
  font-size: var(--title-font-size);
  border-width: 0px;
  border-image: initial;
  border-color: inherit;
  border-style: initial;
  padding: 1.5rem;
  outline: 2px solid rgba($color: #000000, $alpha: 0);
  border-radius: var(--border-radius);
  // background: rgba(51, 51, 51);
  background: rgba($color: $kbreeder-violet, $alpha: 0.2);
  font-weight: 700;
  transition: outline-color 0.15s ease-out, box-shadow 0.15s ease-out;

  &:focus {
    outline-color: rgba($color: $kbreeder-pink, $alpha: 0.6);
    box-shadow: 0 0 10px rgba($color: $kbreeder-pink, $alpha: 0.3);
  }
}

.stake__claim__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  text-align: center;
  background: #502d63;
  padding: 1rem 0;
  cursor: pointer;
  border-radius: var(--border-radius-medium);
  transition: background 0.15s ease-out, color 0.15s ease-out;

  // Avoid user selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    background: #683f7d;
  }
}

.hidden {
  display: none;
}

.box {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 200px;
  padding-left: 10%;
  /* padding-right: 10%; */
}
.return-btn {
  position: absolute;
  background: rgb(216, 216, 216);
  color: #502d63;
  width: 100px;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  padding: 1%;
  border-radius: 30px;
}
.return-btn:visited {
  color: #502d63;
}
.return-btn:hover {
  background: #502d63;
  color: rgb(216, 216, 216);
}
.info-box {
  position: relative;
  min-width: 280px;
  width: 20%;
  padding: 0 20px;
  transform: translateX(80px);
  display: inline-block;
  text-align: left;
  background: #1e1a21;
  color: white;
  box-shadow: 0px 2px 1px #502d63;
  border-radius: 7px;
}
.text-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.pool-info {
  margin-bottom: 60px;
}

.fr {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 400px) {
    align-items: left;
  }
}

.navLink {
  display: flex;
  align-items: center;
  color: none;
  // border: 1px solid green;
  &:hover {
    .downArrowImage {
      transform: translateY(0.5rem);
    }
  }
}

.downArrowImage {
  rotate: 90deg;
  color: var(--title);
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  transition: transform 0.15s ease-in-out;
}
