.main__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.pool__info__wrapper {
  width: min(70rem, 98vw);
}