.staking__options__page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.staking__options__wrapper {
  width: min(90rem, 98vw);
  min-width: 320px;
}

.tvl__yield__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-inline: 1rem;
  margin-bottom: 1rem;
}
