.cls-7 {
    font-size: 53.42px;
    font-family: 'Future-Earth';
    color: var(--brand);
}

.cls-8 {
    font-size: 36.44px;
    font-family: var(--text-font);
    font-weight: bold;
}

.cls-9 {
    font-size: 36.44px;
    font-family: var(--text-font);
    font-weight: 700;
}