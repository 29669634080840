.inter__small__title {
  &h6 {
    font-size: var(--small_title-font-size);
    font-family: var(--text-font-2);
    line-height: var(--small-title-line-height-2);
    color: var(--title);
  }
}

// Margin Left Auto
.mla {
  margin-left: auto;
}

// Margin Righ Auto
.mra {
  margin-right: auto;
}

// Margin Righ 1rem
.mr1 {
  margin-right: 1rem;
}

// Margin top 1 rem
.mt1r {
  margin-top: 1rem;
}

// Flex Row
.fr {
  display: flex;
  flex-direction: row;
}

// Space Between
.sb {
  justify-content: space-between;
}

// Align items center
.aic {
  align-items: center;
  align-content: center;
}

// Padding Left 0.5rem
.pl {
  padding-left: 0.5rem;
}

// Padding left 1rem
.pl-1 {
  padding-left: 1rem;
}

// Padding 1rem
.padding-1 {
  padding: 1rem;
}

// Margin left 1rem
.ml-1 {
  margin-left: 1rem;
}

.input__endContentFixed {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;

  height: var(--text-line-height);
  padding: 2rem 1.5rem;
  border-radius: var(--border-radius-round);
  -webkit-border-radius: var(--border-radius-round);
  -moz-border-radius: var(--border-radius-round);
  border: 2px solid var(--swapx-grey-lighter);
  background: var(--bg-4);

  p {
    margin-right: 0.1rem;
  }

  input {
    padding-right: 0.5rem;
  }

  &:focus-within {
    border: 2px solid var(--brand);
  }

  &-active {
    border: 2px solid var(--swapx-brand);
  }

  &-error {
    border: 2px solid red !important;
    p,
    input {
      color: red !important;
    }
  }
}

.no__user__select {
  // Avoid user selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.flex__row__space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loading-blink {
  animation: loadingText 1s infinite var(--loading-beizer, cubic-bezier(.51,.45,.51,1.08)) ;
}

.loading-darken {
  opacity: 0.6;
}

// * Covers all the bg of a box. Used for messages in which you don't want the user to be able to interact with the rest of the app
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background: rgba($color: $bg-6, $alpha: 0.5);
  // backdrop-filter: blur(1.5px);
  // background: black;

  &-warning {
    border-radius: var(--border-radius-large);
    -webkit-border-radius: var(--border-radius-large);
    -moz-border-radius: var(--border-radius-large);
  }
}

.no__visible__to__visible {
  animation: noOpacityToVisible 0.3s ease;
}

.te__al__ri {
  text-align: right;
}

// .hover__message {
//   position: absolute;
//   height: fit-content;
//   font-size: var(--medium-text-font-size);
//   border-radius: var(--border-radius);
//   border: 1.5px solid var(--text);
//   background: var(--bg-4);
//   z-index: 2;

//   &__inner {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     padding: var(--padding-swap-container-horizontal) var(--padding-swap-container-horizontal);
//     overflow: hidden;
//     z-index: 3;

//     &::after {
//       content: "";
//       position: absolute;
//       bottom: -6rem;
//       right: -2rem;
//       width: 20rem;
//       height: 20rem;
//       background-image: url('../img/header/main.png');
//       background-size: 100%;
//       background-repeat: no-repeat;
//       // background-position: 22rem;
//       // background-position-y: 1rem;
//       opacity: 0.1;
//       z-index: -1;
//     }
//   }
// }

.cursor__question {
  cursor: help;
}

.cursor__default {
  cursor: default;
}

.question__mark__menu {
  padding: 0.25rem 0.55rem;
  border: 1px solid var(--text);
  border-radius: var(--border-radius-round);
  font-size: 1.3rem;
  line-height: 1em;
}

.bold {
  font-weight: bold;
}

.scroll__y {
  overflow-y: scroll;

  scrollbar-color: darken($color: $swapx-brand, $amount: 5%) var(--transparent);


  &::-webkit-scrollbar {
    width: 1rem;
    &-track {
      background: var(--swapx-grey-lighter);
      background: var(--swapx-grey);
      background: none;
    }
  
    &-thumb {
      background: darken($color: $swapx-brand, $amount: 5%);
      border: none;
      &:hover {
        background: darken($color: $swapx-brand, $amount: 0%);
      }
    }
  }
}