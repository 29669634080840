@import 'src/assets/scss/variables';

.enforce_eth_chain__prompt {
  &__cover {
    position: relative;
    background: rgba($color: $swapx-grey-lighter, $alpha: 0.2);
    border-radius: var(--border-radius-medium);
    z-index: 3;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    .switch-network-button {
      a {
        background: var(--kbreeder-brand);
      }
    }
    &__info {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
      justify-content: center;
      gap: 2rem;
      p {
        font-size: var(--text-font-size) !important;
        font-family: var(--text-font-2) !important;
        line-height: var(--small-text-line-height) !important;
      }
      img {
        width: 100%;
      }
      svg {
        height: 10rem;
        margin: 0 auto;
        color: rgba($color: $kbreeder-pink, $alpha: 0.5);
      }
    }
    &__button_container {
      // height: 58px;
      width: 100%;
      img {
        width: 24px;
        height: 24px;
      }

      &__provider_container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
      }
    }
  }
}

.buttons__container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}