@import 'src/assets/scss/variables';

.select_wallet_connection__prompt {
  &__cover {
    position: relative;
    background: rgba($color: $swapx-grey-lighter, $alpha: 0.2);
    border-radius: var(--border-radius-medium);
    z-index: 3;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    &__button_container {
        height: 58px;
        width: 100%;
        img {
          width: 24px;
          height: 24px;
        }

      &__provider_container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
      }
    }

  }
}
