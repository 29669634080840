@import '../../assets/scss/variables.scss';

.prompt {
    position: relative;
    display: flex;
    flex-direction: column;
    width: max(95vw, 320px);
    max-width: 43.2rem;
    max-height: 100vh;  // * So the prompt never overflows the screen vertically
    background: var(--bg-4);
    border-radius: var(--border-radius-medium);
    -webkit-border-radius: var(--border-radius-medium);
    -moz-border-radius: var(--border-radius-medium);
    z-index: 3;

    // * Firefox scrollbar
    scrollbar-color: darken($color: $swapx-brand, $amount: 5%) var(--transparent);
    // * Chrome scrollbar
    &::-webkit-scrollbar {
        width: 1rem;
        &-track {
        background: var(--swapx-grey-lighter);
        background: var(--swapx-grey);
        background: none;
        }
    
        &-thumb {
        background: darken($color: $swapx-brand, $amount: 5%);
        border: none;
        &:hover {
            background: darken($color: $swapx-brand, $amount: 0%);
        }
        }
    }

    @media screen and (max-height: 520px) {
        max-height: 90vh;
    }
    

    p {
        font-size: var(--text-font-size-2);
        font-family: var(--text-font-2);
        line-height: var(--small-text-line-height);
    }

    h6 {
        color: $kbreeder-violet;
    }

    &-longer {
        max-width: 50rem;
    }

    &-title {
        h6 {
           color: var(--title); 
        }
    }

    &-warning {
        h6 {
            color: var(--warning-red-darker);
        }
        
    }

    &__close {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        font-weight: bold;
        border-radius: var(--border-radius-round);
        color: var(--title);
        cursor: pointer;
        transition: color 0.3s ease;
        &:hover {
          color: darken($color: $title, $amount: 20%);
        }
      }

    &__head {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: var(--padding-swap-container-vertical) var(--padding-swap-container-horizontal);
        border-top-left-radius: var(--border-radius-medium);
        border-top-right-radius: var(--border-radius-medium);
        // background: var(--bg-5);
        background: rgba($color: $kbreeder-brand, $alpha: 0.7);
        backdrop-filter: blur(10px);

        overflow: hidden;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            // background: rgba($color: $swapx-brand, $alpha: 0.1);
            z-index: -1;
        }

        &-warning {
            &::after {
            background: rgba($color: $warning-red, $alpha: 0.1);

            }
        }
    }

    &__body {
        overflow-x: hidden;
        padding: var(--padding-swap-container-vertical) var(--padding-swap-container-horizontal);
        border-bottom-left-radius: var(--border-radius-medium);
        border-bottom-right-radius: var(--border-radius-medium);
        // background: var(--bg-6);
        background: rgba($color: $kbreeder-brand, $alpha: 0.4);
        color: var(--title);

        // * Firefox scrollbar
        scrollbar-color: darken($color: $swapx-brand, $amount: 5%) var(--transparent);
        // * Chrome scrollbar
        &::-webkit-scrollbar {
            width: 0.5rem;
            &-track {
                background: none;
            }
        
            &-thumb {
                background: darken($color: $swapx-brand, $amount: 5%);
                border: none;
                &:hover {
                    background: darken($color: $swapx-brand, $amount: 0%);
                }
            }
        }

        @media screen and (max-height: 520px) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &-clear {
            background: var(--bg-4);
        }
    }

    &__cover {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius-medium);
        z-index: 6;
    }
}