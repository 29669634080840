@import 'src/assets/scss/variables.scss';

.loader {
    border: 4px solid $kbreeder-violet;
    border-top-color: var(--title);
    // border-bottom-color: var(--brand);
    border-radius: var(--border-radius-round);
    animation: loaderRotation 1s   infinite  linear;
}

@keyframes loaderRotation {
    0% {transform: rotate(-10deg);}
    35% {transform: rotate(280deg);}
    100% {transform: rotate(350deg);}
}