@font-face {
  font-family: 'Future-Earth';
  src: local('Future-Earth'), url('../fonts/Future-Earth-v2.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Xolonium';
  src: local('Xolonium'), url('../fonts/Xolonium-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
}
