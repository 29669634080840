@import 'src/assets/scss/variables';

.account_details__prompt {
  &__cover {
    position: relative;
    background: rgba($color: $swapx-grey-lighter, $alpha: 0.2);
    border-radius: var(--border-radius-medium);
    z-index: 3;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    &__action_button_container {
      display: flex;
      justify-content: flex-end;
    }
    &__wallet_text {
      p {
        color: var(--title);
      }
    }
  }

  &__buttons {
    display: flex;
    column-gap: 2rem;
    align-items: center;
    &__copy_address, &__open_explorer {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      color: var(--swapx-brand) !important;
      cursor: pointer;
      p {
        font-size: var(--small-text-font-size) !important;
      }
      svg {
        color: var(--swapx-brand) !important;
      }
    }
    &__open_explorer {
      color: var(--text) !important;
      svg {
        color: var(--text) !important;
      }
    }
  }
}
