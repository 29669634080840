@import 'src/assets/scss/variables.scss';

.button__container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  height: fit-content;
  align-items: center;
  align-content: center;
  padding: 2rem;
  border-radius: var(--border-radius);
  // border-left: 0.3rem solid var(--kbreeder-brand);
  // border-right: 0.3rem solid var(--kbreeder-brand);
  color: var(--text);
  // background: var(--bg-4);
  background: rgba($color: $kbreeder-violet, $alpha: 0.15);

  transition: background 0.2s ease-out, color 0.2s ease-out;

  // Avoid user selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    color: var(--title);
    background: var(--kbreeder-brand);
  }

  .images__wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    // width: 100%;
    // justify-content: center;

    &__container {
      position: relative;
      display: flex;
      width: 7rem;
      gap: 0.5rem;
      align-items: flex-end;

      img {
        height: 6rem;
        width: 6rem;
        border-radius: 100%;
        background: var(--title);
        border: 0.3rem solid var(--title);
        box-shadow: 2px -2px 5px inset rgba($color: #000000, $alpha: 0.3),
          0 0 5px inset rgba($color: #000000, $alpha: 0.3);
      }

      .eth__image {
        position: absolute;
        right: 0px;
        // margin-left: -25px;
        height: 3rem;
        width: 3rem;
        border-width: 0.15rem;
      }
    }

    &:first-child {
      // margin-right: 1rem;
    }
  }

  .tokenName__wrapper {
  }

  .apy__wrapper {
  }

  .total__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    text-align: right;
    // width: 100%;
    // min-width: fit-content;
    p {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
    }
  }
}

.df_fr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mla {
  margin-left: auto;
}
