@import '../../assets/scss/variables.scss';

$padding: 4rem;

.staking__options__container {
  position: relative;
  // display: grid;
  // grid-template-columns: repeat(1, 1fr);
  // gap: 1rem;
  height: fit-content;
  width: 100%;
  margin-bottom: 5rem;
  padding: $padding;
  padding-top: 1rem;
  padding-bottom: 3rem;
  // background: var(--bg-6);
  background: linear-gradient(
    45deg,
    rgba($color: $kbreeder-pink, $alpha: 0.2),
    rgba($color: $kbreeder-brand, $alpha: 0.4)
  );
  // border: 2px solid var(--kbreeder-brand);
  border-radius: var(--border-radius-medium);

  @media screen and (max-width: 585px) {
    padding: $padding;
    padding-inline: 4rem;
  }
}

.table {
  &__main__wrapper {
    display: table;
    height: fit-content;
    width: 100%;

    @media screen and (max-width: 585px) {
      display: none;
    }
  }

  &__head {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    display: table-header-group;
    .table__cell {
      background: none;
      border-bottom: none;
    }
  }

  &__body {
    display: table-row-group;
  }

  &__row {
    display: table-row;
    border-radius: var(--border-radius);
    overflow: hidden;
    margin-bottom: 1rem;
    // border-bottom: 2rem solid var(--transparent);
    height: 100%;
    border-radius: var(--border-radius-medium);
    transition: background 0.15s ease-out, color 0.15s ease-out;

    &:not(:first-of-type),
    &:not(:last-of-type) {
      border-bottom: 2rem solid var(--transparent);
    }

    &-clickable {
      .table__cell {
        cursor: pointer;
      }
      &:hover {
        .table__cell {
          // background: var(--kbreeder-brand);
          background: rgba($color: $kbreeder-brand, $alpha: 0.5);
        }
      }
    }
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    padding: 2rem;
    // min-height: 100%;
    // border-bottom: 0.5rem solid var(--kbreeder-brand);
    // box-shadow: 0 0 20px rgba($color: $kbreeder-brand, $alpha: 0.5);
    // background: var(--bg-4);
    background: rgba($color: $kbreeder-violet, $alpha: 0.15);
    transition: background 0.15s ease-out, color 0.15s ease-out;
    &:first-child {
      border-top-left-radius: var(--border-radius-medium);
      border-bottom-left-radius: var(--border-radius-medium);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius-medium);
      border-bottom-right-radius: var(--border-radius-medium);
    }
  }
}

.columnButtons__container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 4rem;
  height: fit-content;

  @media screen and (min-width: 585px) {
    display: none;
  }
}
