@keyframes backgroundAnimated {
  0% {
    background-position: 0% 48%;
  }
  50% {
    background-position: 100% 53%;
  }
  100% {
    background-position: 0% 48%;
  }
}

@keyframes loadingText {
  0%,
  100% {
    color: var(--swapx-grey-lighter);
  }
  50% {
    color: var(--swapx-grey);
  }
}

@keyframes noOpacityToVisible {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}