@import 'src/assets/scss/animations';
@import 'src/assets/scss/variables';

.btn {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  min-width: fit-content;
  white-space: nowrap;
  border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  cursor: pointer;
  transition: color 0.2s ease-out, border 0.2s ease-out, opacity 0.2s ease-out, background 0.2s ease-out;

  font-family: var(--title-font);
  font-size: 1rem;
  line-height: 1em;

  // Break line on long words
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;

  // Avoid user selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::after {
    transition: color 0.2s ease-out, border 0.2s ease-out, opacity 0.2s ease-out, background 0.2s ease-out;
  }

  &__primary {
    color: var(--text);
    border-radius: var(--border-radius-round);
    -webkit-border-radius: var(--border-radius-round);
    -moz-border-radius: var(--border-radius-round);
    font-family: var(--text-font-2);
    font-size: var(--medium-text-font-size);
    line-height: 1em;
    font-weight: bold;
    // border: 0.3rem solid var(--text);
    &:hover:not(.btn__primary-disabled) {
      color: var(--title);
    }

    &-token {
      z-index: 1;
      overflow: hidden;
      border-radius: var(--border-radius);
      border-top: 1.5px solid darken($color: $swapx-brand, $amount: 5%);
      border-bottom: 1.5px solid darken($color: $swapx-brand, $amount: 5%);
      border-left: 1.5px solid var(--transparent);
      border-right: 1.5px solid var(--transparent);

      img {
        border-radius: var(--border-radius-round);
        background: radial-gradient(white 50%, rgba(5, 80, 159, 0.9) 100%);
      }

      &:hover:not(.btn__primary-disabled) {
        border-radius: var(--border-radius-round);
        border-left: 1.5px solid darken($color: $swapx-brand, $amount: 5%);
        border-right: 1.5px solid darken($color: $swapx-brand, $amount: 5%);
        // border-radius: var(--border-radius);
        border-color: var(--swapx-brand);
        // background: var(--swapx-brand);
      }
    }

    &-mainToken {
      z-index: 1;
      overflow: hidden;
      border: 1.5px solid var(--swapx-brand-darker);
      img {
        border-radius: var(--border-radius-round);
        background: radial-gradient(white 50%, rgba(5, 80, 159, 0.9) 100%);
      }

      &:hover:not(.btn__primary-disabled) {
        border: 1.5px solid var(--swapx-brand);
      }
    }

    &-bg {
      font-weight: bold;
      color: var(--text);
      border-radius: var(--border-radius-round);
      -webkit-border-radius: var(--border-radius-round);
      -moz-border-radius: var(--border-radius-round);
      font-family: var(--text-font-2);
      font-size: var(--text-font-size-2);
      line-height: var(--inter-line-height);
      background: var(--bg-4);
      border: 1.5px solid var(--swapx-grey);
      transition: background 0.1s ease-out;
      &:hover {
        color: var(--title);
        background: $swapx-brand-clearer;
        transition: background 0.2s ease-out;
      }
      &-active {
        color: var(--title) !important;
        background: var(--swapx-brand);
        &:hover {
          background: var(--swapx-brand);
        }
      }
    }

    &-border {
      border-radius: var(--border-radius);
      border: 1.5px solid currentColor;
    }

    &-disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &__wallet {
    text-align: center;
    overflow: hidden;
    z-index: 1;
    background: none;
    color: $kbreeder-violet;
    opacity: 0.85;
    border: 0.2rem solid var(--kbreeder-brand);
    gap: 0.5rem;

    &:hover {
      border-color: var(--kbreeder-brand);
      color: var(--text);
      // color: var(--brand);
      opacity: 1;
      background-color: var(--kbreeder-brand);
    }
  }

  &__swap {
    color: var(--bg-6);
    // background: rgba($brand, 0.85);
    // border: 2px solid var(--brand);
    text-align: center;
    font-family: var(--text-font);
    font-size: var(--small-title-font-size-2);
    font-weight: bold;
    // overflow: hidden;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid var(--brand);
      background: rgba($brand, 0.85);
      border-radius: var(--border-radius);
      -webkit-border-radius: var(--border-radius);
      -moz-border-radius: var(--border-radius);
      z-index: -1;
    }

    &:hover {
      // color: var(--title);
      // border-color: var(--title);
    }

    &-disabled {
      cursor: not-allowed;
      // color: darken($color: currentColor, $amount: 20%);
      opacity: 0.8;
      &::after {
        opacity: 0.6;
      }
    }

    &-loading,
    &-noAmount,
    &-cancel,
    &-import {
      &:hover:not(.btn__swap-disabled) {
        &::after {
          border-color: var(--swapx-brand);
          background: rgba($swapx-brand, 0.75);
        }
      }
      &::after {
        background: rgba($swapx-brand, 0.4);
        border-color: rgba($swapx-brand, 0.3);
      }
    }

    &-loading {
      color: var(--title);
      font-family: var(--text-font-2);
      font-size: var(--text-font-size);
      line-height: var(--inter-line-height);
    }

    &-noAmount {
      min-width: unset;
      color: var(--text);
      font-family: var(--text-font-2);
      font-size: var(--text-font-size);
      line-height: var(--inter-line-height);
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-incorrectNetwork {
      min-width: unset;
      color: var(--text);
      font-family: var(--text-font-2);
      font-size: var(--small-text-font-size);
      line-height: var(--inter-line-height);
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      &::after {
        background: rgba($warning-red, 0.4);
        border-color: rgba($warning-red, 0.3);
      }
    }

    &-cancel {
      color: var(--text);
    }

    &-import {
      color: var(--text);
      font-family: var(--text-font-2);
      font-size: var(--text-font-size);
      line-height: var(--inter-line-height);

      &::after {
        background: rgba($brand, 0.7);
        border-color: rgba($brand, 0.5);
      }

      &:hover {
        color: var(--title);
        &::after {
          background: rgba($brand, 1);
          border-color: rgba($brand, 1);
        }
      }
    }

    &-approve {
      margin-bottom: var(--margin-token-container);
      min-width: unset;
      justify-content: left;
      color: var(--text);
      font-family: var(--text-font-2);
      font-size: var(--medium-text-font-size);
      line-height: var(--inter-line-height);
      text-align: left;
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      // word-wrap: break-word;

      &:hover:not(.btn__swap-disabled) {
        &::after {
          border-color: var(--swapx-brand);
          background: rgba($swapx-brand, 0.75);
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-color: var(--swapx-brand);
        background: rgba($swapx-brand, 0.55);
        border-radius: var(--border-radius);
        -webkit-border-radius: var(--border-radius);
        -moz-border-radius: var(--border-radius);
        z-index: -1;
      }
    }

    &-warning {
      // color: var(--warning-red);
      color: var(--text);

      &.btn__swap-disabled {
        color: grey;
      }

      &::after {
        background: rgba($warning-red, 0.8);
        border-color: rgba($warning-red-darker, 1);
      }
    }
  }

  &__blockchain {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    border-radius: var(--border-radius);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    font-family: var(--text-font-2);
    font-size: var(--medium-text-font-size);
    line-height: 1em;
    font-weight: bold;
    // border-bottom: 1px solid currentColor;
    // border-left: 1px solid currentColor;
    // border-right: 1px solid currentColor;

    // border-radius: 2px;
    // -webkit-border-radius: 2px;
    // -moz-border-radius: 2px;
    // font-family: var(--text-font-2);
    // box-shadow: 0 0 35px inset currentColor;

    // clip-path: polygon(0 0, 100% 0, 100% 100%, 1.5rem 100%, 0 calc(100% - 1.5rem));

    color: var(--text);

    &-borderBottom {
      border-bottom: 1px solid currentColor;
    }

    &-borderBottomTop {
      border-top: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
    }

    &-borderLeftRight {
      border: none;
      border-left: 1px solid currentColor;
      border-right: 1px solid currentColor;
    }

    img {
      width: 2.2rem;
      height: 2.2rem;
      margin-right: 0.7rem;
      border-radius: var(--border-radius-round);
    }

    svg {
      fill: currentColor;
    }

    &-eth {
      color: $color-eth;
      &:hover {
        color: lighten($color: $color-eth, $amount: 10%);
      }
    }
    &-bsc {
      color: $color-bsc;
      &:hover {
        color: lighten($color: $color-bsc, $amount: 10%);
      }
    }
    &-polygon {
      color: $color-polygon;
      &:hover {
        color: lighten($color: $color-polygon, $amount: 10%);
      }
    }
    &-avalanche {
      color: $color-avalanche;
      &:hover {
        color: lighten($color: $color-avalanche, $amount: 10%);
      }
    }
    &-fantom {
      color: $color-fantom;
      &:hover {
        color: lighten($color: $color-fantom, $amount: 10%);
      }
    }
    &-arbitrum {
      color: $color-arbitrum;
      &:hover {
        color: lighten($color: $color-arbitrum, $amount: 10%);
      }
    }
  }

  &__small {
    padding: 0.5rem;
  }

  &__md {
    padding: 1rem;
    &__longer {
      padding: 0.7rem 1.5rem;
    }
  }
  &__prepend_icon {
    padding-right: 1.6rem;
    svg {
      margin-left: 0.6rem;
      margin-right: 0.6rem;
      width: 2rem;
      height: 2rem;
    }
  }

  &__large {
    padding: 1.5rem;
  }

  &__token {
    padding: 0.7rem 1rem;

    img,
    .btn__svg__image {
      margin-right: 0.5rem;
      height: 2.4rem;
      width: 2.4rem;
      aspect-ratio: 1 / 1;
      border-radius: var(--border-radius-round);
      -webkit-border-radius: var(--border-radius-round);
      -moz-border-radius: var(--border-radius-round);
    }
  }

  &__slippage {
    padding: 2rem 1.5rem;
    height: var(--button-height);
  }
}

.btn__down__arrow {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

.token__name {
  max-width: 10rem; // * 13rem for DORAEMONINU to fit
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blockchain__name {
  max-width: 13rem; // * 13rem for DORAEMONINU to fit
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image__fallback {
  margin-right: 0.5rem;
  height: 2.4rem;
  width: 2.4rem;
  aspect-ratio: 1 / 1;
  background: var(--title);
  border-radius: var(--border-radius-round);
  -webkit-border-radius: var(--border-radius-round);
  -moz-border-radius: var(--border-radius-round);
}
