@import './assets/scss/variables.scss';

body {
  // background: var(--bg-5);
  background: rgba($color: darken($kbreeder-brand, 15%), $alpha: 1);
}

.wildcardRef {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-height: 520px) {
    top: unset;
    bottom: 0;
    // right: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 90vh;
  }
}
