@import 'fonts.scss';
@import 'normalize.scss';
@import 'variables';
@import 'animations';
@import 'generic-styles';

*,
*::before,
*::after {
  scrollbar-color: $kbreeder-brand var(--bg-1);
  scrollbar-width: thin;
}
#root {
  min-height: 100vh;
}
html {
  min-height: 100%;
  font-size: 62.5%; // 10px

  @media screen and (max-width: 1584px) {
    // 1% smaller
    font-size: 56.25%; // 9px
  }
  @media screen and (max-width: 1263px) {
    // 2% smaller
    // font-size: 50%; // 8px
    font-size: 53.1%; // 8.5px
  }
  @media screen and (max-width: 740px) {
    // 3% smaller
    font-size: 43.75%; // 7px?
    // font-size: 50%;
  }
}

body {
  font-family: var(--text-font-2);
  font-size: var(--text-font-size);
  line-height: var(--text-line-height);
  color: var(--text);
  scrollbar-color: $kbreeder-brand var(--bg-1);
  scrollbar-width: thin;
  min-height: 100%;
}

// Title - Opens a row
h2 {
  font-family: var(--title-font);
  font-size: var(--title-font-size);
  line-height: var(--title-line-height);
  color: var(--title);
}

// Title2 - References to something important inside a row: eg. Product Cards title, Buy button
h3 {
  font-family: var(--title-font);
  font-size: var(--title2-font-size);
  line-height: var(--title2-line-height);
  color: var(--title);
}

// Subtitle1 - For lists titles
h4 {
  font-family: var(--title-font);
  font-size: var(--small-title-font-size);
  line-height: var(--small-title-line-height);
  color: var(--title);
}

// Subtitle2 - Text directly after a Title and explaining it a bit further
h5 {
  font-family: var(--text-font);
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
  color: var(--title);
}

h6 {
  font-size: var(--text-font-size-2);
  font-family: var(--text-font-2);
  line-height: var(--small-text-line-height);
  color: var(--title);
}

.container {
  margin: 0 auto;
  // max-width: 1024px;
}

ul {
  list-style: none;
}


::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;

  &-track {
    background: var(--swapx-grey);
    border-radius: var(--border-radius);
    margin-block: 0.2rem;
    margin-inline: 0.2rem;
  }

  &-thumb {
    background: darken($color: $kbreeder-brand, $amount: 5%);
    border: 0.25rem solid var(--swapx-grey);
    border-radius: var(--border-radius);
    &:hover {
      background: var(--bg-3);
      background: darken($color: $kbreeder-brand, $amount: 9%);
    }
  }
}


