@import '../../../assets/scss/variables.scss';

.button__container {
  position: relative;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 2rem;
  border-radius: var(--border-radius);
  color: var(--text);

  // Avoid user selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    color: var(--title);
  }

  .images__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-end;
    width: 7rem;
    height: 100%;
    width: 100%;
    gap: 0.5rem;

    img {
      height: 6rem;
      width: 6rem;
      border-radius: 100%;
      background: var(--title);
      border: 0.3rem solid var(--title);
      box-shadow: 2px -2px 5px inset rgba($color: #000000, $alpha: 0.3),
        0 0 5px inset rgba($color: #000000, $alpha: 0.3);
    }

    .eth__image {
      position: absolute;
      left: 6.1rem;
      height: 3rem;
      width: 3rem;
      border-width: 0.15rem;
    }
  }

  .tokenName__wrapper {
  }

  .apy__wrapper {
  }

  .total__wrapper {
    // display: flex;
    // flex-direction: column;
    // height: 100%;
    // width: 100%;
    // min-width: fit-content;
    p {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
    }
  }
}
